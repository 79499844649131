<template>

  <div>
    <div id="chat-wrapper" class="live-chat-feed">
      <div class="container">
        <div v-for="message in this.messages" :key="message.id" class="row message-bubble">
          <p class="text-muted">{{ message.author }}</p>
          <p>{{ message.content }}</p>
        </div>
      </div>
    </div>
    <div class="panel-footer">
      <div class="input-group">
        <input v-model="message" class="form-control o-input" placeholder="Enter your message" type="text"
               @keydown.enter="sendMessage">

        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" @click="sendMessage">Send</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import ChatWindow from 'vue-advanced-chat';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';

export default {
  name: 'PresentationChat',
  components: {
    ChatWindow,
  },
  data: () => {
    return {
      presentation: {},
      messages: [],
      message: '',
      connection: null,
      subscribe: false
    };
  },
  mounted() {
    this.doWebsocketConnection();
  },
  methods: {
    doWebsocketConnection() {

      this.connection = new WebSocket('wss://chat.osidex.delivery');

      this.connection.onclose = () => {
        setTimeout(this.doWebsocketConnection, 1500);
      };

      this.connection.onerror = e => {
        console.log(e);
      };

      this.connection.onopen = () => {

        if (!localStorage.getItem('name')) {
          localStorage.setItem('name', `User ${Math.round(Math.random() * 1000)}`)
        }

        this.connection.send(JSON.stringify({
          event: "subscribe",
          channel: "6092cfed44a727001245a710",
          author: localStorage.getItem('name') || 'Guest 0000'
        }))
      }

      this.connection.onmessage = (message) => {

        message = JSON.parse(message.data);

        if (message.event === 'message') {
          this.onChatReceived(
              Math.floor(Math.random() * 1000000),
              message.author,
              message.message,
              Date.parse(message.timestamp),
          );
        }

      };

    },
    onChatReceived(id, author, content, timestamp) {

      this.messages.push({id, author, content, timestamp});

      setTimeout(() => {
        let objDiv = document.getElementById('chat-wrapper');
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 100);
    },
    sendMessage() {

      if (!this.message) return;

      this.connection.send(JSON.stringify({
        event: "message",
        channel: "6092cfed44a727001245a710",
        message: this.message
      }))


      this.message = '';
    },
  },
};
</script>

<style scoped>

.o-wrapper{
  background-color:#303030;
  padding-top:-80px;
}

.o-input {
  background-color:#303030;
  border-color: #3b3b3b;
}

.message-bubble {
  padding: 10px 0px 10px 0px;
}

.message-bubble:nth-child(even) {
  background-color: #505050;
}

.message-bubble > * {
  padding-left: 10px;
  color: #fff6f6;
}


.panel-body {
  padding: 0px;
  overflow: scroll;
}

.panel-heading {
  background:linear-gradient(65deg, #91334d, #3b53d0);
  color: white !important;
}
</style>
